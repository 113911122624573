const defaultRegion = "asia-northeast1";
const bodyHasKeyValue = (body, key) => {
  if (isUorNull(body)) {
    return false;
  }
  if (isUorNull(body[key])) {
    return false;
  }
  return true;
};

const isNumValue = (value) => {
  if (isUorNull(value)) {
    return false;
  }
  return value.replace(/[ ]/g, "") != "" && !isNaN(value);
};

const isUorNull = (value) => {
  if (typeof value === "undefined" || value == null) {
    return true;
  }
  return false;
};

const isMultilingual = (body, key) => {
  if (bodyHasKeyValue(body, key)) {
    return bodyHasKeyValue(body[key], "ja") || bodyHasKeyValue(body[key], "en");
  }
  return false;
};

const isArrayObject = (body, key) => {
  if (bodyHasKeyValue(body, key)) {
    return Array.isArray(body[key]);
  }
  return false;
};

export default {
  defaultRegion,
  bodyHasKeyValue,
  isUorNull,
  isNumValue,
  isMultilingual,
  isArrayObject,
};
