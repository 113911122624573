// import fetch from 'node-fetch'
import common from "./common.js";
import { fb } from "./firebase.js";

const initMeta = function () {
  return {
    title: {
      ja: "",
      en: "",
    },
    description: {
      ja: "",
      en: "",
    },
    published: "",
    license: "",
    rights: "",
    categories: [],
    tags: [],
    note: "",
    created: new Date(),
    updated: new Date(),
  };
};

const initContacts = function () {
  return {
    name: "",
    url: "",
    email: "",
  };
};

const initContactsWithUser = (user) => {
  return {
    name: user.displayName,
    url: "",
    email: user.email,
  };
};

const initAppMeta = function () {
  return {
    creator: "",
    users: [],
    lastEditor: "",
    status: "draft",
    previousVersion: 0,
  };
};

const initAppMetaWithUser = (user) => {
  return {
    creator: user.uid,
    users: [user.uid],
    lastEditor: user.uid,
    status: "draft",
    previousVersion: 0,
  };
};

const initDistribution = function () {
  return {
    title: "",
    description: "",
    downloadURL: "",
    type: "url",
    format: "SKOS",
    created: new Date(),
    updated: new Date(),
    metadata: {
      id: "",
      lastEditor: "",
      creator: "",
    },
  };
};

const initDistributionWithFileObj = (format) => {
  const dist = initDistribution();
  dist.type = "url";
  dist.file = new Object();
  if (format) {
    dist.format = format;
  }
  return dist;
};

const transformMetaToDCAT = (base, contacts, app_metadata, docId, type) => {
  const time = fb.firestore.FieldValue.serverTimestamp();
  const dict = {
    "dcat:identifier": docId,
    "dct:title": base.title,
    "dct:publisher": base.published,
    "dct:license": base.license,
    "dct:rights": base.rights,
    "dcat:theme": base.categories,
    "dcat:keywords": base.tags,
    "dct:description": base.description,
    "skos:changeNote": base.note,
    "dcat:contactPoint": [
      {
        "vcard:fn": contacts.name,
        "vcard:hasEmail": contacts.email,
        "vcard:hasURL": contacts.url,
      },
    ],
    app_metadata: app_metadata,
    "dcat:modified": base.updated,
    "dcat:issued": base.created,
  };
  if (type === "create") {
    dict["dcat:issued"] = time;
    dict["dcat:modified"] = time;
  }
  if (type === "update") {
    dict["dcat:modified"] = time;
  }
  return dict;
};

const transformDistributionToDCAT = (distObj, type) => {
  const time = fb.firestore.FieldValue.serverTimestamp();
  const dict = {
    "dct:title": distObj.title,
    "dct:description": distObj.description,
    "dcat:downloadURL": distObj.downloadURL,
    "dct:format": distObj.format,
    metadata: distObj.metadata,
    "dcat:issued": distObj.created,
    "dcat:modified": distObj.updated,
  };
  if (type === "create") {
    dict["dcat:issued"] = time;
    dict["dcat:modified"] = time;
  }
  if (type === "update") {
    dict["dcat:modified"] = time;
  }
  return dict;
};

const transformBaseMetaFromDCAT = (body) => {
  const base = initMeta();
  const errMsg = [];
  if (common.isMultilingual(body, "dct:title")) {
    base.title = body["dct:title"];
  } else {
    errMsg.push("dct:title format is wrong.");
  }
  if (common.isMultilingual(body, "dct:description")) {
    base.description = body["dct:description"];
  } else {
    errMsg.push("dct:description format was wrong.");
  }
  if (common.bodyHasKeyValue(body, "dct:publisher")) {
    base.published = body["dct:publisher"];
  } else {
    errMsg.push("dct:publisher is required.");
  }
  if (common.bodyHasKeyValue(body, "dct:license")) {
    base.license = body["dct:license"];
  } else {
    errMsg.push("dct:license is required.");
  }

  if (common.bodyHasKeyValue(body, "dct:rights")) {
    base.rights = body["dct:rights"];
  }

  if (common.bodyHasKeyValue(body, "dcat:theme")) {
    if (Array.isArray(body["dcat:theme"])) {
      base.categories = body["dcat:theme"];
    } else {
      errMsg.push("dcat:theme must be string array");
    }
  }

  if (common.bodyHasKeyValue(body, "dcat:keywords")) {
    if (Array.isArray(body["dcat:keywords"])) {
      base.tags = body["dcat:keywords"];
    } else {
      errMsg.push("dcat:keywords must be string array");
    }
  }
  if (common.bodyHasKeyValue(body, "skos:changeNote")) {
    base.note = body["skos:changeNote"];
  }

  if (common.bodyHasKeyValue(body, "dcat:issued")) {
    base.created = body["dcat:issued"];
  }
  if (common.bodyHasKeyValue(body, "dcat:modified")) {
    base.updated = body["dcat:modified"];
  }
  return {
    data: base,
    err: errMsg,
  };
};

// アップデート用, 存在するキーだけのバリデーション
const transformUpdateBaseMetaFromDCAT = (body) => {
  const base = {};
  const errMsg = [];
  if (common.bodyHasKeyValue(body, "dct:title")) {
    if (common.isMultilingual(body, "dct:title")) {
      base.title = body["dct:title"];
    } else {
      errMsg.push("dct:title format is wrong.");
    }
  }
  if (common.bodyHasKeyValue(body, "dct:description")) {
    if (common.isMultilingual(body, "dct:description")) {
      base.description = body["dct:description"];
    } else {
      errMsg.push("dct:description format was wrong.");
    }
  }

  if (common.bodyHasKeyValue(body, "dct:publisher")) {
    base.published = body["dct:publisher"];
  }
  if (common.bodyHasKeyValue(body, "dct:license")) {
    base.license = body["dct:license"];
  }
  if (common.bodyHasKeyValue(body, "dct:rights")) {
    base.rights = body["dct:rights"];
  }

  if (common.bodyHasKeyValue(body, "dcat:theme")) {
    if (Array.isArray(body["dcat:theme"])) {
      base.categories = body["dcat:theme"];
    } else {
      errMsg.push("dcat:theme must be string array");
    }
  }

  if (common.bodyHasKeyValue(body, "dcat:keywords")) {
    if (Array.isArray(body["dcat:keywords"])) {
      base.tags = body["dcat:keywords"];
    } else {
      errMsg.push("dcat:keywords must be string array");
    }
  }
  if (common.bodyHasKeyValue(body, "skos:changeNote")) {
    base.note = body["skos:changeNote"];
  }
  return {
    data: base,
    err: errMsg,
  };
};

//  TODO: メールのバリデーション
const transformContacsFromDCAT = (body) => {
  const contacts = initContacts();
  const errMsg = [];

  if (common.bodyHasKeyValue(body, "dcat:contactPoint")) {
    if (Array.isArray(body["dcat:contactPoint"])) {
      const data = body["dcat:contactPoint"][0];
      if (common.bodyHasKeyValue(data, "vcard:hasEmail")) {
        contacts.email = data["vcard:hasEmail"];
      } else {
        errMsg.push("vcard:hasEmail is required.");
      }
      if (common.bodyHasKeyValue(data, "vcard:fn")) {
        contacts.name = data["vcard:fn"];
      } else {
        errMsg.push("vcard:fn is required.");
      }
      if (common.bodyHasKeyValue(data, "vcard:hasURL")) {
        contacts.url = data["vcard:hasURL"];
      }
    } else {
      errMsg.push("dcat:contactPoint must be array");
    }
  } else {
    errMsg.push("dcat:contactPoint is required.");
  }
  return {
    data: contacts,
    err: errMsg,
  };
};

// アップデート用 存在するキーだけのバリデーション TODO: メールのバリデーション
const transformUpdateContacsFromDCAT = (body) => {
  const contacts = {};
  const errMsg = [];

  if (common.bodyHasKeyValue(body, "dcat:contactPoint")) {
    if (Array.isArray(body["dcat:contactPoint"])) {
      const data = body["dcat:contactPoint"][0];
      if (common.bodyHasKeyValue(data, "vcard:hasEmail")) {
        contacts.email = data["vcard:hasEmail"];
      }
      if (common.bodyHasKeyValue(data, "vcard:fn")) {
        contacts.name = data["vcard:fn"];
      }
      if (common.bodyHasKeyValue(data, "vcard:hasURL")) {
        contacts.url = data["vcard:hasURL"];
      }
    } else {
      errMsg.push("dcat:contactPoint must be array");
    }
  }
  return {
    data: contacts,
    err: errMsg,
  };
};

const transformDistributionFromDCAT = (body) => {
  const distribution = initDistribution();
  const errMsg = [];

  if (common.bodyHasKeyValue(body, "dct:title")) {
    distribution.title = body["dct:title"];
  } else {
    errMsg.push("dct:title is required.");
  }
  if (common.bodyHasKeyValue(body, "dcat:downloadURL")) {
    distribution.downloadURL = body["dcat:downloadURL"];
  } else {
    errMsg.push("dcat:downloadURL is required.");
  }

  if (common.bodyHasKeyValue(body, "dct:format")) {
    const format = body["dct:format"];
    if (format === "SKOS" || format === "OWL" || format === "RDF") {
      distribution.format = format;
    } else {
      errMsg.push("Only SKOS, OWL, RDF are permitted for dct:format.");
    }
  } else {
    errMsg.push("dct:format is required.");
  }

  if (common.bodyHasKeyValue(body, "dct:description")) {
    distribution.description = body["dct:description"];
  }
  if (common.bodyHasKeyValue(body, "dcat:issued")) {
    distribution.created = body["dcat:issued"];
  }
  if (common.bodyHasKeyValue(body, "dcat:modified")) {
    distribution.updated = body["dcat:modified"];
  }
  if (common.bodyHasKeyValue(body, "metadata")) {
    distribution.metadata = body.metadata;
  }
  return {
    data: distribution,
    err: errMsg,
  };
};

const transformDistDataFromVocabularyToObjList = (vocabulary) => {
  const distributions = vocabulary["dcat:distribution"].map((item) => {
    const vD = transformDistributionFromDCAT(item);
    if (vD.err.length > 0) {
      throw new Error(JSON.stringify(vD.err));
    }
    return vD.data;
  });
  return distributions;
};

// アップデート用, 存在するキーだけのバリデーション
const transformUpdateDistributionFromDCAT = (body) => {
  const distribution = {};
  const errMsg = [];

  if (common.bodyHasKeyValue(body, "dct:title")) {
    distribution.title = body["dct:title"];
  }
  if (common.bodyHasKeyValue(body, "dcat:downloadURL")) {
    distribution.downloadURL = body["dcat:downloadURL"];
  }

  if (common.bodyHasKeyValue(body, "dct:format")) {
    const format = body["dct:format"];
    if (format === "SKOS" || format === "OWL" || format === "RDF") {
      distribution.format = format;
    } else {
      errMsg.push("Only SKOS, OWL, RDF are permitted for dct:format.");
    }
  }

  if (common.bodyHasKeyValue(body, "dct:description")) {
    distribution.description = body["dct:description"];
  }
  return {
    data: distribution,
    err: errMsg,
  };
};

// アップデート用, ステータスだけチェックする
const transformUpdateAppMetaFromDCAT = (body) => {
  const meta = {};
  if (common.bodyHasKeyValue(body.app_metadata, "status")) {
    meta.status = body.app_metadata.status;
  }
  return {
    data: meta,
    err: [],
  };
};

const convertVocabularyToObject = (vocabulary) => {
  const rBase = transformBaseMetaFromDCAT(vocabulary);
  const rContact = transformContacsFromDCAT(vocabulary);
  if (rBase.err.length > 0 || rContact.err.length > 0) {
    Array.prototype.push.apply(rBase.err, rContact.err);
    throw new Error(JSON.stringify(rBase.err));
  }
  const distributions = transformDistDataFromVocabularyToObjList(vocabulary);
  return {
    base: rBase.data,
    contacts: rContact.data,
    app_meta: vocabulary.app_metadata,
    distributions: distributions,
  };
};

const createFileNameFromUrl = (url) => {
  const fileArray = url.split("/");
  if (fileArray.length == 0) {
    return '';
  }
  let fileName = fileArray[fileArray.length - 1];
  fileName = fileName.split("?").shift();
  fileName = fileName.split("#").shift();
  // storageの公開URLはバケット内の[/]が[%2F]として扱われている
  const fileArray2 = fileName.split("%2F");
  if (fileArray2.length > 2) {
    fileName = fileArray2[fileArray2.length - 1];
  }
  return fileName;
}

export default {
  initMeta,
  initContacts,
  initContactsWithUser,
  initAppMeta,
  initAppMetaWithUser,
  initDistribution,
  initDistributionWithFileObj,

  transformMetaToDCAT,
  transformDistributionToDCAT,

  transformBaseMetaFromDCAT,
  transformUpdateBaseMetaFromDCAT,
  transformContacsFromDCAT,
  transformUpdateContacsFromDCAT,
  transformUpdateAppMetaFromDCAT,
  transformDistributionFromDCAT,
  transformUpdateDistributionFromDCAT,

  transformDistDataFromVocabularyToObjList,
  convertVocabularyToObject,

  createFileNameFromUrl,
};
